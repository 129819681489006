import React,{useState,useEffect} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Form, Modal } from 'react-bootstrap';
import { viewProfile } from '../../api/user';
import { getCategoryList } from '../../api/category';
import { addSubCategory, getSubCategoryList } from '../../api/sub-category';
import { addCity, getCity } from '../../api/city';
import { addArea, getAreaList } from '../../api/area';
import { v4 as uuidv4 } from 'uuid';

const EditProfile = ({profileShow,setProfileShow,handleProfileClose}) => {
    const [file, setFile] = useState();
    const [imageKey, setImageKey] = useState("");
    const [name, setName] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [category, setCategory] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [categoryList, setCategoryList] = useState([]);
    const [subCategory, setSubCategory] = useState("");
    const [categoryId, setCategoryId] = useState("");
    const [subCategoryLists, setSubCategoryLists] = useState([]);
    const [subCategoryId, setSubCategoryId] = useState("");
    const [cityLists, setCityLists] = useState([]);
    const [city, setCity] = useState("");
    const [cityId, setCityId] = useState("");
    const [areaLists, setAreaLists] = useState([]);
    const [area, setArea] = useState("");
    const [areaId, setAreaId] = useState("");
    const [address, setAddress] = useState("");
    const [fillUp, setFillUp] = useState(false);
    const savedUserProfile = localStorage.getItem("niomit");
    const userProfile = JSON.parse(savedUserProfile);

    useEffect(() => {
        getCategoryList().then((data) => {
            setCategoryList(data);
        })
        getSubCategoryList().then((data) => {
            setSubCategoryLists(data);
        })
        getCity().then((data) => {
            setCityLists(data);
        })
        getAreaList().then((data) => {
            setAreaLists(data);
        })
    }, []);

    function handleChange(e) {
        setFile(URL.createObjectURL(e.target.files[0]));
        setImageKey(e.target.files[0])
    }
    const handlePass = (event) => {
        const value = event.target.value;
        setUserPassword(value);
    };
    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
    };
    const [filteredResults, setFilteredResults] = useState(subCategoryLists);
    const handleSubCategory = (e) => {
        const value = e.target.value;
        setSubCategory(value);
        if (value.trim() === '') {
            setFilteredResults([]);
        } else {
            const results = subCategoryLists.filter(subCat =>
                subCat.sub_category.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredResults(results);
        }
    };

    const [filteredCity, setFilteredCity] = useState(cityLists);
    const handleCity = (e) => {
        const value = e.target.value;
        setCity(value);
        if (value.trim() === '') {
            setFilteredCity([]);
        } else {
            const results = cityLists.filter(subCat =>
                subCat.city.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredCity(results);
        }
    };


    const [filteredArea, setFilteredArea] = useState(areaLists);
    const handleArea = (e) => {
        const value = e.target.value;
        setArea(value);
        if (value.trim() === '') {
            setFilteredArea([]);
        } else {
            const results = areaLists.filter(subCat =>
                subCat.area.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredArea(results);
        }
    };

    useEffect(() => {
        viewProfile(userProfile?.mobile).then((data) => {
            setName(data.data.user_name);
            setAddress(data.data.address);
            setArea(data.data.area);
            setCity(data.data.city);
            setSubCategory(data.data.sub_category);
            setCategoryName(data.data.category);
             setCategoryId(data.data.categoryId);
        })

    }, [])

    const updateProfile = () => {
        let categoryInfo = {
            image: imageKey,
            password: userPassword,
            user_name: name,
            category: category.length ? category : "",
            address: address,
        };
    
        // Handle subCategory logic
        if (subCategoryId) {
            categoryInfo.subCategory = subCategoryId;
        } else {
            let subCat = uuidv4();
            let subCategoryInfo = {
                id: subCat,
                category: category.length ? category : categoryId,
                sub_category: subCategory,
                created_at: new Date(),
            };
            categoryInfo.subCategory = subCat;
            addSubCategory(subCategoryInfo).then((data) => {
                setSubCategory("");
            });
        }
    
        // Handle city logic
        if (cityId) {
            categoryInfo.city = cityId;
        } else {
            let cityCode = uuidv4();
            let cityInfo = {
                id: cityCode,
                city: city,
                created_at: new Date(),
            };
            categoryInfo.city = cityCode;
    
            addCity(cityInfo).then((data) => {
                setCity("");
            });
        }
    
        // Handle area logic
        if (areaId) {
            categoryInfo.area = areaId;
        } else {
            let areaCode = uuidv4();
            let areaInfo = {
                id: areaCode,
                city: cityId.length ? cityId : uuidv4(),
                area: area,
                created_at: new Date(),
            };
            categoryInfo.area = areaCode;
    
            addArea(areaInfo).then((data) => {
                setArea("");
            });
        }
    
        console.log('categoryInfo', categoryInfo);  // Should now show all data correctly
    };
    

    return (
        <Modal show={profileShow} onHide={handleProfileClose} centered className="loginModal">
            <div className="loginApp">
                <h1 style={{ fontSize: "32px" }}>Profile</h1>
                <div className="inputs">

                    <div className="text-center imgFile">{file && file.length > 0 ? <img src={file} alt="shop" /> : ""}</div>

                    <div className='inputImgField'>
                        <input type="file" name="file" id="file" onChange={handleChange} className="inputFile" />
                        <label for="file"><FontAwesomeIcon icon={faPlus} /> Choose a file</label>
                    </div>
                    <input type="text" name="userName" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter your name" />
                    <input type="password" name="password" value={userPassword} onChange={handlePass} placeholder="Enter your password" />
                    <div className='inputField mt-3'>
                        <label>Select Profile</label>
                        <Form.Select aria-label="Default select example" onChange={handleCategoryChange}>
                            <option value="">{categoryName.length ? categoryName : "Select Profile Option"}</option>
                            {categoryList.map((category) =>
                                <option value={`${category.id}`}>{category.category}</option>
                            )}
                        </Form.Select>
                    </div>
                    <input type="text" name="userName" value={subCategory} onChange={handleSubCategory} placeholder="Enter your sub-category" />
                    <ul className='filterList'>
                        {filteredResults.map((subCat, index) => (
                            <li key={index} onClick={() => { setSubCategoryId(subCat.id); setSubCategory(subCat.sub_category); }}>{subCat.sub_category}</li>
                        ))}
                    </ul>
                    <input type="text" name="userName" value={city} onChange={handleCity} placeholder="Enter your city" />
                    <ul className='filterList'>
                        {filteredCity.map((subCat, index) => (
                            <li key={index} onClick={() => { setCityId(subCat.id); setCity(subCat.city); }}>{subCat.city}</li>
                        ))}
                    </ul>
                    <input type="text" name="userName" value={area} onChange={handleArea} placeholder="Enter your area" />
                    <ul className='filterList'>
                        {filteredArea.map((subCat, index) => (
                            <li key={index} onClick={() => { setAreaId(subCat.id); setArea(subCat.area); }}>{subCat.area}</li>
                        ))}
                    </ul>
                    <input type="text" name="userName" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Enter your address" />

                    {fillUp && fillUp ? <p className="text-center text-danger text-bold">Please fillup all the information</p> : ""}

                    <div className="profileFooter">
                        <button onClick={updateProfile}>Update</button>
                    </div>

                </div>
            </div>
        </Modal>
    )
}

export default EditProfile