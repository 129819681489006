import React, { useEffect, useState } from 'react'
import { postCommentList, viewPost } from '../../api/post';
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { API } from "../../config";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCubes, faEllipsis, faLink, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { Col, Container, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import EditPost from '../../Component/EditPost/EditPost';
import CommentReply from '../../Component/CommentReply/CommentReply';
import Header from '../../Component/Header/Header';
import { createdTime } from '../../Function/commonFunction';

const ViewPost = () => {
    const { id } = useParams();
    const [postData, setPostData] = useState({});
    const [viewShow, setViewShow] = useState(false);
    const handleViewClose = () => setViewShow(false);
    const handleViewShow = () => setViewShow(true);
    const [commentData, setCommentData] = useState([]);
    const [commentShow, setCommentShow] = useState(false);
    const handleCommentClose = () => setCommentShow(false);
    const [commentId, setCommentId] = useState("");
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    useEffect(() => {
        viewPost(id).then((data) => {
            setPostData(data.data[0]);
        })
        postCommentList(id,page,limit).then((data) => {
            setCommentData(data.comments)
        })
    }, [count]);

    const replyComment = (commentId) => {
        setCommentId(commentId);
        setCommentShow(true);
    }

    return (
        <div>
        <Header />
        <Container>
            <Row className='d-flex justify-content-center'>
                <Col md={12}>
                    <div className="myCard">
                        <div className="row align-items-center">
                            <div className="col-auto postImage">
                                <figure>
                                    <LazyLoadImage src={`${API}/${postData.user_image}`} alt="" className="img-fluid cardImg" />
                                </figure>
                            </div>
                            <div className="col cardHeading">
                                <h3>
                                    <a href={`post/${postData.id}`}>{postData.user_name}</a>
                                </h3>
                                <div className="d-flex">
                                    <p className="card-link">
                                        <FontAwesomeIcon icon={faCubes} />
                                        {postData.user_category}
                                    </p>
                                    <p className="card-link">
                                        <FontAwesomeIcon icon={faLocationArrow} />
                                        {createdTime(postData.created_at)}
                                    </p>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="vertical-center">
                                    <div className="vertical-center mobileIcon">
                                        <DropdownButton id="dropdown-basic-button" title={<FontAwesomeIcon icon={faEllipsis} />}>
                                            <Dropdown.Item onClick={handleViewShow}>Edit</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Report</Dropdown.Item>
                                        </DropdownButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className='postDescription'>
                                    <p>{postData.post_description}</p>
                                </div>
                                <div className=''>
                                    <figure>
                                        <LazyLoadImage src={`${API}/${postData.post_image}`} alt="" className="img-fluid postImg" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='commentList'>
                        {commentData.length && commentData.map((comment) => <div>
                            <div class="row align-items-center">
                                <div class="col-auto postImage">
                                    <figure>
                                        <img src={`${API}/${comment.comment_user_image}`} alt="" class="img-fluid cardImg" /></figure>
                                </div>
                                <div class="col cardHeading"><h3>{comment.comment_user_name}</h3>
                                    <div class="d-flex card-link"><p>{createdTime(comment.comment_created_at)}</p></div></div>
                                <div className="col-auto">
                                    <div className="vertical-center mobileIcon">
                                        <DropdownButton id="dropdown-basic-button" title={<FontAwesomeIcon icon={faEllipsis} />}>
                                            <Dropdown.Item href="#/action-1">Edit</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Delete</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Report</Dropdown.Item>
                                        </DropdownButton>
                                        {/*   <button onClick={()=>replyComment(comment.comment_id)}>Reply</button> */}
                                    </div>
                                </div>
                            </div>
                            <div class="row align-items-center card-link commentText">
                                <p>{comment.comment_text}</p>
                                <button className='replyBtn'>Reply</button>
                            </div>
                            {comment.replies.map((reply) => <div className='commentReply'><div class="row align-items-center">
                                <div class="col-auto postImage">
                                    <figure>
                                        <img src={`${API}/${reply.reply_user_image}`} alt="" class="img-fluid cardImg" /></figure>
                                </div>
                                <div class="col cardHeading"><h3>{reply.reply_user_name}</h3>
                                    <div class="d-flex card-link"><p>{createdTime(reply.reply_created_at)}</p></div></div>
                            </div>
                                <div class="align-items-center commentText">
                                    {reply.reply_text}
                                </div></div>)}
                        </div>)}
                    </div>
                </Col>
            </Row>
            <EditPost count={count} setCount={setCount} viewShow={viewShow} setViewShow={setViewShow} handleViewClose={handleViewClose} />
            <CommentReply commentId={commentId} postId={id} commentShow={commentShow} handleCommentClose={handleCommentClose} />
        </Container>
        </div>
    )
}

export default ViewPost