
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Header.css';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faE, faLockOpen, faSearch, faUser, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { NavDropdown } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Registration from '../../Pages/User/Registration/Registration';
import { logout } from '../../api/admin';
import Post from '../Post/Post';
import { API } from "../../config";
import news from "../../Assets/icon/news logo.svg";
import profile from "../../Assets/icon/profile logo.svg";
import logo from "../../Assets/icon/main logo.svg";
import { viewProfile } from '../../api/user';

const Header = ({ searchInput, setSearchInput, type, setType }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [loginShow, setLoginShow] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [profileData, setProfileData] = useState({});


    const [postShow, setPostShow] = useState(false);
    const handlePostClose = () => setPostShow(false);
    const handlePostShow = () => setPostShow(true);

    const savedUserProfile = localStorage.getItem("niomit");
    const userProfile = JSON.parse(savedUserProfile);
 
    useEffect(() => {
        viewProfile(userProfile?.mobile).then((data) => {
            console.log('profileData',data);
            setProfileData(data.data);
        })
    }, [])

    const logoutBtn = () => {
        logout();
        return navigate(`/`);
    };

    const handleBackClick = () => {
        navigate(-1);
    };

    const loginOption = () => {
        setLoginShow(!loginShow)
    };

    return (
        <>
            <div className='headerNav'>
                <ToastContainer />
                <Container>
                    <Row className="d-flex justify-content-center align-items-center">
                        <Col md={12}>
                            <Nav className="d-flex justify-content-between w-100 align-items-center flex-row navHeader">
                                {location.pathname === "/account" ? <Nav.Link className='homeIcon' onClick={handleBackClick}><img width="32px" src={logo} alt="news" /></Nav.Link>
                                    : <> {type === "post" ? <Nav.Link className='homeIcon' onClick={() => setType('profile')}><img width="32px" src={profile} alt="news" /></Nav.Link>
                                        : location.pathname.split('/')[1] === "post" ? <Nav.Link className='homeIcon' onClick={handleBackClick}><FontAwesomeIcon icon={faArrowLeft} /></Nav.Link> : <Nav.Link className='homeIcon' onClick={() => setType('post')}><img width="32" src={news} alt="news" /></Nav.Link>}</>}

                                <div className="input-group-search">
                                    {location.pathname.split('/')[1] === "post" ? <p>Post details</p>
                                        : <input type="text" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} placeholder="Search" />}
                                </div>
                                {location.pathname === "/account" ? <Nav.Link className='headerUser'><img src={`${API}/${profileData?.image}`} alt="profile" /></Nav.Link>
                                    : <>{userProfile?.mobile ?
                                        <>
                                            <Nav.Link className='headerUser' href={`${profileData?.mobile}`}><img src={`${API}/${profileData?.image}`} alt="profile" /></Nav.Link>
                                        </>
                                        :
                                        <button className='userIcon' onClick={loginOption}><FontAwesomeIcon icon={faUser} /></button>
                                    }</>}

                            </Nav>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Registration loginShow={loginShow} setLoginShow={setLoginShow} />
            <Post postShow={postShow} setPostShow={setPostShow} handlePostClose={handlePostClose} />
        </>
    )
}

export default Header